<template>
  <div>
    <section class="import-file-section">
      <div class="instructions">
        <img
          alt="download-icon"
          src="@/assets/custom-icons/download-icon.svg"
        >
        <p>
          <button
            class="btn-unset"
            @click="downloadSpreadsheet"
          >
            Faça o download
          </button>
          da planilha de vacinados , verifique os dados e preencha os campos faltantes.
          Após verificação, salve no formato .xlsx e importe a planilha.
        </p>
      </div>
      <div class="file-input">
        <p>Importar planilha:</p>
        <b-form-file
          v-model="sheetFile"
          size="md"
          class="customFileButton"
          placeholder="Nenhum arquivo selecionado"
          drop-placeholder="Insira o arquivo aqui..."
          browse-text="Escolher arquivo"
          variant="primary"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          @change="handleFileChange"
        />
        <small
          v-if="!canDoUpload"
          style="color: #ff1010"
        >Este campo é de preenchimento obrigatório</small>
      </div>

      <CustomModalComponent
        :show-modal="showErrorModal"
        kind="error"
        title="Erro na Importação"
        content="O modelo da planilha que está sendo utilizado na importação não é mais válido. Verifique e tente novamente"
        confirm-button-label="Ok"
        @confirmed="confirmActionErrorModal"
      />

      <CustomModalComponent
        :show-modal="this.$data.showSuccessModal"
        kind="success"
        title="Importação em andamento"
        content="Notificaremos assim que acabar."
        confirm-button-label="Ok"
        @confirmed="confirmActionSuccessModal"
      />
    </section>

    <section class="import-file-button">
      <b-button
        id="import-colaborador"
        class="mr-2 mb-2-sm"
        variant="custom-blue"
        :disabled="disabledButton"
        @click="importFileHandler"
      >
        <feather-icon
          icon="DownloadIcon"
          class="mr-50"
        />
        <span
          v-if="disabledButton"
          class="align-middle"
        >Processando...</span>
        <span
          v-if="!disabledButton"
          class="align-middle"
        >Importar</span>
      </b-button>
    </section>
  </div>
</template>

<script>
import { BButton, BFormFile } from 'bootstrap-vue'
import moment from 'moment/moment'
import CustomModalComponent from '@/views/custom-pages/Vacinacao/Pages/Importacao/Modal/CustomModalComponent.vue'
import { applyCpfMask } from '@/helpers/string'

export default {
  components: {
    CustomModalComponent,
    BButton,
    BFormFile,
  },
  data() {
    return {
      company: null,
      sheetFile: null,
      showSuccessModal: false,
      showErrorModal: false,
      canDoUpload: true,

      disabledButton: false,
    }
  },

  computed: {
    getSearch() {
      return this.$store.getters['vacinacaoState/getSearch']
    },

    idCampanha() {
      return this.getSearch.campanha.id_campanha
    },

    empresa() {
      return this.getSearch.empresa
    },

    unidade() {
      return this.getSearch.unidade
    },

    descricaoCampanha() {
      return this.getSearch.campanha.descricao_campanha
    },

    tipoCampanha() {
      return this.getSearch.campanha.tipo
    },

    idEmpresaEndereco() {
      return this.getSearch.endereco.id_empresa_endereco || null
    },

    empresaEndereco() {
      if (!this.getSearch.endereco) {
        return null;
      }

      const endereco = this.getSearch.endereco;
      let enderecoCompleto = "";
      enderecoCompleto += endereco.logradouro + ', ';
      enderecoCompleto += (endereco.numero || 'S/N') + ' - ';
      enderecoCompleto += endereco.bairro + ', ';
      enderecoCompleto += endereco.nome_cidade + ' - ';
      enderecoCompleto += endereco.uf + ', ';
      enderecoCompleto += (endereco.cep || '');
      return enderecoCompleto;
    },
  },

  methods: {
    async downloadSpreadsheet() {
      await this.$http.get(this.$api.colaborador(), {
        params: {
          paginacao: false,
          vacinado: false,
          ordem: 'asc',
          id_empresa: this.empresa.id_empresa,
          id_campanha: this.idCampanha,
          id_categoria: '',
          cpf: '',
          ano: moment().format('YYYY'),
          ativo: true,
        },
      }).then(response => {
        if (response.status === 200) {
          const content = response.data.data
            .filter(el => !el.vacinado)
            .map(item => ({
              VACINADO: 'Não',
              'DATA DE APLICAÇÃO': '',
              'CPF VACINADOR': '',
              LOTE: '',
              NOME: item.nome,
              CPF: applyCpfMask(item.cpf),
              CATEGORIA: item.categoria,
              CNPJ: this.empresa.documento_formatado,
              "ID EMPRESA ENDEREÇO": this.idEmpresaEndereco,
              "ENDEREÇO": this.empresaEndereco,
            }))

          this.$helpers.downloadArchiveXlsx(content, 'importacao_vacinacao.xlsx')
        }
      })
    },
    async importFileHandler() {
      this.disabledButton = true

      if (!this.sheetFile) {
        this.canDoUpload = false
        this.disabledButton = false
        return
      }

      const formData = new FormData()
      formData.append('arquivo', this.sheetFile)
      formData.append('id_empresa', this.empresa.id_empresa)
      formData.append('cnpj', this.empresa.documento_empresa)
      formData.append('id_unidade', this.unidade.id_unidade)
      formData.append('id_campanha', this.idCampanha)
      formData.append('tipo', this.tipoCampanha)
      formData.append('id_empresa_endereco', this.idEmpresaEndereco)
      
      await this.$http.post(this.$api.importarVacinados(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        this.showSuccessModal = true
        this.refreshTable()
        this.sheetFile = null
        this.canDoUpload = true
      }).catch(() => {
        this.showErrorModal = true
      })

      this.disabledButton = false
    },
    handleFileChange(event) {
      this.sheetFile = event.target.files.length > 0 ? event.target.files[0] : null
      this.canDoUpload = !!this.sheetFile
    },
    refreshTable() {
      this.$emit('refresh')
    },
    confirmActionSuccessModal() {
      this.showSuccessModal = false
    },
    confirmActionErrorModal() {
      this.showErrorModal = false
    },
  },
}
</script>

<style scoped lang="scss">
.import-file-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2em;
  border: 1px solid #D8D6DE;
  border-radius: 6px;

  a {
    text-decoration: underline;
  }

  .instructions,
  .file-input {
    width: 50%;
  }

  .file-input {
    padding: 0 2.5em;
    cursor: pointer;

    p {
      margin-bottom: 10px;
    }

  }

  .instructions {
    background: #F3F2F7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2.5em;
    gap: 2em;

    p {
      color: #6E6B7B;
      font-size: 1.1rem;
      margin: auto 0;
    }
  }
}

.import-file-button {
  width: 100%;
  margin: 2em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-unset {
  border: none;
  background: transparent;
  padding: 0;
  color: #2772C0;
  text-decoration: underline;
}

@media (max-width: 425px) {
  .import-file-section {
    flex-direction: column;
  }

  .instructions,
  .file-input {
    width: 100% !important;
  }
}

.customFileButton {
  ::after {
    color: #2772c0 !important;
    border: 1px solid #2772c0 !important;
    border-radius: 4px !important;
  }
}
</style>
