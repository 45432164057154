<script>
import { BButton, BFormFile } from 'bootstrap-vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import { VACINACAO_VIEWS } from '@/views/custom-pages/Vacinacao/Helpers/utils'

export default {
  computed: {
    VACINACAO_VIEWS() {
      return VACINACAO_VIEWS
    },
  },
  methods: {
    redirectTo(view = VACINACAO_VIEWS.REGISTRO) {
      this.$router.push('resumo-registro-vacinacao?v=' + view)
    },
  },
  components: {
    PageHeader, BButton, BFormFile
  },
  props: {
    showImportView: Function,
  },
  data() {
    return {
      sheetFile: null,
      linkItems: [
        {
          name: 'Tutorial de importação',
          active: true,
        },
        {
          name: 'Registro de vacinação',
          active: true,
        },
      ],
    }
  }
  ,
}
</script>

<template>
  <div class="wrapper">
    <div class="card py-2">
      <h1 class="mb-2">{{ this.linkItems[0].name }}</h1>

      <b-button
          variant="none"
          class="outline-btn"
          v-on:click="() => {
            this.redirectTo(VACINACAO_VIEWS.IMPORTAR)
          }">
        <feather-icon icon="ArrowLeftIcon" class="mr-50" />
        <span class="align-middle">Voltar para importação</span>
      </b-button>

      <div class="content-wrapper">
        <section class="step-list">

          <!-- STEP 1 -->
          <div class="step">
            <img alt="check-document-tip" src="@/assets/images/svg/check-document-tip.svg" style="max-width: 95px"/>
            <div class="summary">
              <h3>1</h3>
              <div class="vertical-line"></div>
            </div>
            <div class="description">
              <h3>Download da planilha</h3>
              <p>Com a planilha, você poderá verificar os dados dos vacinados e completar os campos com informações
                faltantes.</p>
            </div>
          </div>

          <!-- STEP 2 -->
          <div class="step">
            <img alt="check-document-tip" src="@/assets/images/svg/check-column-order.svg" style="max-width: 95px"/>
            <div class="summary">
              <h3>2</h3>
              <div class="vertical-line"></div>
            </div>
            <div class="description">
              <h3>Ordem das colunas</h3>
              <p>
                Antes de preencher e revisar sua planilha, verifique se ela está com as colunas nas posições corretas:
                <b>VACINADO, DATA DE APLICAÇÃO, CPF VACINADOR, LOTE, NOME, CPF, CATEGORIA, CNPJ, ID EMPRESA ENDEREÇO</b> e <b>ENDEREÇO</b>.
              </p>
            </div>
          </div>

          <!-- STEP 3 -->
          <div class="step">
            <img alt="check-document-tip" src="@/assets/images/svg/check-status-vacinado.svg" style="max-width: 95px"/>
            <div class="summary">
              <h3>3</h3>
              <div class="vertical-line"></div>
            </div>
            <div class="description">
              <h3>Verificar vacinados</h3>
              <p>
                Verifique o status de vacinação dos cadastrados e assinale <b>SIM</b> caso eles já tenham sido
                vacinados. Ao importar a planilha com esse campo em branco, o status da vacinação não será atualizado.
              </p>
            </div>
          </div>

          <!-- STEP 4 -->
          <div class="step">
            <img alt="check-document-tip" src="@/assets/images/svg/check-rows.svg" style="max-width: 95px"/>
            <div class="summary">
              <h3>4</h3>
              <div class="vertical-line"></div>
            </div>
            <div class="description">
              <h3>Verificar dados</h3>
              <p>
                Antes de realizar a importação, verifique se houve alterações nos dados dos colaboradores exportados. Os
                dados devem coincidir com os registros existentes no sistema.
              </p>
            </div>
          </div>

          <!-- STEP 5 -->
          <div class="step">
            <img alt="check-document-tip" src="@/assets/images/svg/import-status.svg" style="max-width: 95px"/>
            <div class="summary">
              <h3>5</h3>
              <div class="vertical-line"></div>
            </div>
            <div class="description">
              <h3>Download da planilha</h3>
              <p>
                Após a importação da planilha, você poderá acompanhar o status de processamento dos dados em uma tabela
                disponível no menu “Registro de vacinados” dentro da opção “Importações”. Nessa tabela haverá um
                indicativo do andamento do processo, podendo ser:
              </p>

              <ul>
                <li><p><b>Concluído:</b> Quando o sistema concluir a importação dos dados.</p></li>
                <li><p><b>Erro:</b> Quando ocorre algum erro de dados na planilha.</p></li>
                <li><p><b>Em andamento:</b> Quando o processo de importação da planilha estiver ocorrendo.</p></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  gap: 1em;
  margin: 0 auto;

  .step-list {
    width: 100%;
    border: 1px solid #D8D6DE;
    margin-top: 2em;
    border-radius: 12px;
    padding: 0 2em;

    display: grid;

    .step {
      display: flex;
      flex-direction: row;
      gap: 30px;
      margin: 2em 0;

      img {
        padding: 1em 0;
      }

      .summary {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {
          background: #2772C0;
          color: #FFF;
          padding: 5px;
          aspect-ratio: 1/1;
          text-align: center;
          border-radius: 100em;
        }

        .vertical-line {
          height: 70%;
          width: 1px;
          background: #BABFC7;
        }
      }

      .description {
        * {
          p {
            width: 60%;
          }

          ul {
            list-style: none;
          }
        }
      }
    }
  }
}

.file-input {
  width: 100%;
  border: 1px solid #D8D6DE;
  background: #0020600A;

  padding: 2em;
  border-radius: 12px;

  p {
    margin-bottom: 10px;
  }
}


.default-btn {
  background: #2772C0;
  color: #fff;
  width: fit-content;
}

.default-btn:hover {
  color: #FFF;
}

.outline-btn {
  border: 1px solid #2772C0;
  color: #2772C0;
  background: none;
  width: fit-content;
}

.custom-file-label::after {
  color: #2772c0 !important;
  border: 1px solid #2772c0 !important;
  border-radius: 4px !important;
}

.outline-btn:hover {
  color: #2772C0;
}

@media (max-width: 768px) {
  .content-wrapper {
    width: 100%;
  }
  img {
    display: none;
  }
}
</style>
